<template>
  <div>
    <header v-bind:class="$router.history.current.name == 'UserDashboard' ? 'header_home' : 'header_home sub active'">
      <div class="header__inner">
        <a href="/" class="header__logo">이트너스</a>
        <ul class="header__menu">
          <li v-for="(mainMenuItem, index) in menuList">
            <a v-bind:href="mainMenuItem.urlLinkTx" class="menu__depth1" v-bind:target="getTargetMode(mainMenuItem)">{{mainMenuItem.menuNmTx}}</a>
            <div class="menu__depth2">
              <li v-for="(childMenuItem, childIndex) in mainMenuItem.childMenuList">
                <a v-bind:href="childMenuItem.urlLinkTx"
                   v-bind:target="getTargetMode(childMenuItem)">{{childMenuItem.menuNmTx}}</a>
              </li>
            </div>
          </li>
        </ul>
      <div class="header__link">
        <a href='/qna/question/download' style="cursor: pointer;">회사소개서</a>
        <a href='/location' alt="찾아오시는길">LOCATION</a>
        <a href="/en/main/dashboard" target="_self" alt="영문으로">EN →</a>
      </div>
      <div class="hide-default show_1199 header__btn">
        <div class="btn__wrap">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
  </div>
  <div class="header__sub-bg" style="display: none; opacity: 0;">
  </div>
  </header>
  <div class="side__menu" style="display: none; opacity: 0;">
    <div class="menu__top">
      <div class="top__link">
        <a href='/qna/question/download'  style="cursor: pointer;">회사소개서</a>
        <a href='/location'>LOCATION</a>
        <a href="/en/main/dashboard">EN →</a>
      </div>

      <div class="top__close">
        <img src="@/assets/img/side_close.svg" alt="">
      </div>
    </div>
    <div class="menu__wrap">
      <ul class="menu__list">
        <li v-for="(mainMenuItem, index) in menuList">
          <p :class="mainMenuItem.childMenuList != null ? 'm_depth1' : 'm_depth1 no'">
            <a v-bind:href="mainMenuItem.urlLinkTx">{{mainMenuItem.menuNmTx}}</a>
          </p>
          <div class="m_depth2" v-for="(childMenuItem, childIndex) in mainMenuItem.childMenuList">
            <a v-bind:href="childMenuItem.urlLinkTx"
               v-bind:target="getTargetMode(childMenuItem)">{{childMenuItem.menuNmTx}}
            </a>
          </div>
        </li>
        <li class="msns">
          <p class="m_depth1 no"><a href="//blog.naver.com/etners" target="_blank">
            <img src="@/assets/img/blog_icon.svg" alt="블로그"></a>
            <a href="//www.youtube.com/channel/UCplJe-DdR7EuGgy6WrNadHw" target="_blank">
              <img src="@/assets/img/yout.svg" alt="유튜브"></a></p>
        </li>
      </ul>
    </div>
  </div>
  <!-- //모바일 메뉴 -->
  <div class="header__mask" style="display: none; opacity: 0;"></div>
 </div>

</template>
<script>


  export default {
    created: function () {
      this.$loadScript('/js/gsap.min.js')
      this.$loadScript('/js/slick.js')
      this.$loadScript('/js/slick.min.js')
      this.$loadScript('/js/jquery.waypoints.min.js')
      this.$loadScript('/js/common.js')
      this.$loadScript('/js/main_visual.js')
    },
    computed: {
    },
    methods: {
      getTargetMode: function(menuItem) {
        if(menuItem == null) {
          return '_self'
        }

        if(menuItem.urlLinkTx.indexOf('http') != -1) {
          return '_blank'
        }

        return '_self'
      },
    },
    data: function () {
      let webMenuData = sessionStorage.getItem('webMenuData')
      let menuList = []

      if(webMenuData) {
        menuList = JSON.parse(webMenuData)

        if(menuList.length > 0) {
          menuList.sort(function (a, b) {
            if (a.menuId < b.menuId) {
              return -1
            } else if (a.menuId > b.menuId) {
              return  1
            }
            return 0
          })
          menuList.forEach((menu, i) => {
            if (menu.childMenuList != null && menu.childMenuList.length > 0) {
              menu.childMenuList.sort(function (a, b) {
                if (a.menuId < b.menuId) {
                  return -1
                } else if (a.menuId > b.menuId) {
                  return  1
                }
                return 0
              })
            }
          })
        }
      }


      return {
        existLoginUserDataFl : false,
        loginUserObj : undefined,
        userName : "",
        menuList : menuList,
        webMenuData : webMenuData,
        sideMenuCss : "display: none; opacity: 0;"
      }
    },

  }
</script>
<style>

</style>
